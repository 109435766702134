.main_admin_page{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    min-height: 100vh;
    /*background-color: white;*/
}
.child_main{
    background-color: white;
    border-radius: 10px;
    width: 30%;
    min-height: 45vh;
    box-sizing: border-box;
    position: relative;
}
.logo_company{
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10%;
}
.img-fluid1{
    margin-top: 20px;
    width: 120px;
    height: 120px;
}
.form_data{
    width: 100%;
    padding: 40px 15px 10px 15px;
}


.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.my-pagination {
    width: 100%;
    text-align: center;
}
.pagination {
    margin-top: 3rem;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    font-size: 1.2rem;
    gap: 5px;
}
.page-num {
    background-color: #FCFDFE;
    color: #92B8E4!important;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 400;
}
.page-num:hover{
    background-color: #1677FF;
    color: white;
}
.active {
    background-color: #1677FF;
    color: white;
}

@media (min-width:1140px) and (max-width:1440px){
    .child_main{
        background-color: white;
        border-radius: 10px;
        width: 40%;
        min-height: 45vh;
        box-sizing: border-box;
        position: relative;
    }
}

@media (min-width:992px) and (max-width:1140px){
    .child_main{
        background-color: white;
        border-radius: 10px;
        width: 50%;
        min-height: 45vh;
        box-sizing: border-box;
        position: relative;
    }
}

@media (min-width:768px) and (max-width:992px){
    .child_main{
        background-color: white;
        border-radius: 10px;
        width: 60%;
        min-height: 45vh;
        box-sizing: border-box;
        position: relative;
    }
}

@media (min-width:576px) and (max-width:768px){
    .child_main{
        background-color: white;
        border-radius: 10px;
        width: 70%;
        min-height: 45vh;
        box-sizing: border-box;
        position: relative;
    }
}

@media (min-width:412px) and (max-width:576px){
    .child_main{
        background-color: white;
        border-radius: 10px;
        width: 80%;
        min-height: 45vh;
        box-sizing: border-box;
        position: relative;
    }
}

@media (min-width:280px) and (max-width:412px){
    .child_main{
        background-color: white;
        border-radius: 10px;
        width: 90%;
        min-height: 45vh;
        box-sizing: border-box;
        position: relative;
    }
}