body{
    margin: 0;
    padding: 0;
    background-color: #EEF1F8!important;
}
.col-12{
    position: relative;
}
a{
    text-decoration: none!important;
}
.ant-modal-footer{
    display: none!important;
}
.img-for-logo{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.logo_main_page{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.in_menu_item{
    display: flex;
    align-items: center!important;
    padding: 0 10px 0 0;
}
.anticon-menu-fold{
    color: white!important;
}
.anticon-menu-unfold{
    color: white!important;
}
.anticon-logout{
    display: flex;
    /*justify-content: end!important;*/
    color: white!important;
}

.for_menu_text{
    font-size: 15px;
    font-weight: 400;
    margin-left: 10px;
}
.anticon{
    font-size: 19px!important;
}
.for_min_width{
    display: none!important;
}
.row{
    margin-top: 30px!important;
}
.first_name{
    margin-top: 100px;
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    color:#003f78 ;
}
.for_img{
    margin-top: 90px;
    align-items: center;
    display: flex;
    justify-content: end;
    /*border: 1px solid black;*/
    box-sizing: border-box;
    /*height: 100vh;*/
}
.for_main_img{
    width: 500px;
}
.second_name{
    margin-top: 40px!important;
    text-transform: uppercase;
    color: #003f78;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
}
.third_name{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}
/* .for_select{
    width: 700px!important;
} */
.ant-select{
   width: 634px!important;
}
.second_form_bottom_child{
    position: absolute;
    width: 70%;
    margin-bottom:200px;
    justify-content: center!important;
    margin-left: 15%;
}
.ant-select-selector{
    height: 50px!important;
}
.ant-select-selection-item{
    padding: 10px 0!important;
}
.first_col-6{
    margin-top: 70px!important;
}
.for_label{
    font-weight: bold;
    color: #003f78!important;
    font-size: 20px;
    width: 600px;
}
.zoom-in-right{
    width: 100%;
}
.for_textarea{
    width: 100%!important;
}
.for_label_bottom{
    font-weight: bold;
    color: #003f78!important;
    font-size: 20px!important;
}
.for_textarea:hover{
    transform: scale(1.05);
}
.for_textarea_bottom:hover{
    transform: scale(1.05);
}
.big_button{
    display: flex;
}
.for_button{
    width: 400px;
    height: 50px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: #003f78;
    color: white;
    font-size: 18px;
    font-weight: bold;
    transition: .2s linear;
}
.big_button2{
    display: flex;
    justify-content: center;
    width: 100%;
}
.for_button:hover{
    transition: .2s linear;
    transform: scale(1.05);
}
.for_col_12{
    /*box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);*/
    display: flex;
    justify-content: center;
}
.main_img{
    width: 80%;
    margin: 70px 0!important;
}

.big_main_img{
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (min-width:1140px) and (max-width:1440px){
    /* .ant-select{
        width: 450px!important;
    } */
    .for_img{
        margin-top: -10px;
        align-items: center;
        display: flex;
        justify-content: end;
        /*border: 1px solid black;*/
        box-sizing: border-box;
        /*height: 100vh;*/
    }
    .for_main_img{
        margin:85px 0 ;
        width: 400px;
    }

    .for_label{
        font-weight: bold;
        color: #003f78!important;
        font-size: 20px;
        width: 500px;
    }
    .for_textarea{
        /* width: 450px!important; */
    }
}

@media (min-width:992px) and (max-width:1140px){
    .ant-select{
        /* width: 450px!important; */
    }
    .for_img{
        margin-top: -20px;
        align-items: center;
        display: flex;
        justify-content: end;
        /*border: 1px solid black;*/
        box-sizing: border-box;
        /*height: 100vh;*/
    }
    .for_main_img{
        margin:85px 0 ;
        width: 400px;
    }

    .for_label{
        font-weight: bold;
        color: #003f78!important;
        font-size: 18px;
        width: 450px;
    }
    .for_textarea{
        /* width: 450px!important; */
    }
}
@media (min-width:768px) and (max-width:992px){
    .ant-select{
        /* width: 330px!important; */
    }
    .for_img{
        margin-top: -20px;
        align-items: center;
        display: flex;
        justify-content: end;
        /*border: 1px solid black;*/
        box-sizing: border-box;
        /*height: 100vh;*/
    }
    .for_main_img{
        margin:100px 0 ;
        width: 300px;
    }

    .for_label{
        font-weight: bold;
        color: #003f78!important;
        font-size: 17px;
        width: 300px;
    }
    .for_label_bottom{
        font-size: 17px!important;
    }
    .for_textarea{
        width: 380px!important;
    }
}

@media (min-width:576px) and (max-width:768px){

    .for_big_width{
        display: none;
    }
    .for_min_width{
        display: block!important;
    }
    /* .third_name{
        margin-bottom: 30px;
    } */
    .ant-select{
        margin-top: 30px;
        /* width: 500px!important; */
    }
    .for_img{
        /* margin-top: 40px; */
        align-items: center;
        display: flex;
        justify-content: end;
        /*border: 1px solid black;*/
        box-sizing: border-box;
        /*height: 100vh;*/
    }
    .for_main_img{
        margin: 40px 60px 55px 0;
        width: 400px;
    }

    .for_label{
        font-weight: bold;
        color: #003f78!important;
        font-size: 20px;
        width: 400px;
    }
    .for_textarea{
        /* width: 500px!important; */
    }
    .second_form_bottom_child {
        width: 100%;
        margin-left: 5px;
    }
    .for_label_bottom{
        width: 400px;
    }
    .for_textarea_bottom{
        /* width: 500px!important; */
    }
    .ant-input{
        /* width: 500px!important; */
    }
}

@media (min-width:412px) and (max-width:576px){
    .main_second_width{
        padding:0 15px !important;
        position: relative !important;
    }
    .d-block{
        position: relative !important;
    }
    .for_big_width{
        display: none;
    }
    .for_min_width{
        display: block!important;
    }
    /* .third_name{
        margin-bottom: 30px;
    } */
    .ant-select{
        margin-top: 30px;
        margin-left:3%;
        /* width: 380px!important; */
    }
    .for_img{
        /* margin-top: 40px; */
        justify-content: center!important;
        align-items: center;
        display: flex;
        justify-content: end;
        /*border: 1px solid black;*/
        box-sizing: border-box;
        /*height: 100vh;*/
    }
    .for_main_img{
        margin: 40px  0 40px 0;
        width: 300px;
    }

    .for_label{
        margin-left:3%;
        font-weight: bold;
        color: #003f78!important;
        font-size: 16px;
        width: 350px;
    }
    .for_textarea{
        margin-left:3%;
        /* width: 380px!important; */
    }
    .second_form_bottom_child {
        width: 100%;
        margin-left: 5px;
    }
    .for_label_bottom{
        margin-left:3%;
        width: 350px;
        font-size: 16px!important;
    }
    .for_textarea_bottom{
        margin-left:10%;
        /* width: 380px!important; */
    }
    .ant-input{
        margin-left:1%;
        /* width: 380px!important; */
    }
    .for_button{
        width: 300px;
    }
}
@media (min-width:280px) and (max-width:411px){
    .main_second_width{
        padding:0 15px !important;
        position: relative !important;
    }
    .d-block{
        position: relative !important;
    }
    .for_big_width{
        display: none;
    }
    .for_min_width{
        display: block!important;
    }
    /* .third_name{
        margin-bottom: 30px;
    } */
    .ant-select{
        margin-top: 30px;
        margin-left:3%;
        /* width: 300px!important; */
    }
    .for_img{
        /* margin-top: 40px; */
        align-items: center;
        display: flex;
        justify-content: end;
        /*border: 1px solid black;*/
        box-sizing: border-box;
        /*height: 100vh;*/
    }
    .for_main_img{
        margin: 40px 0 55px 0;
        width: 300px;
    }

    .for_label{
        margin-left:3%;
        font-weight: bold;
        color: #003f78!important;
        font-size: 20px;
        width: 300px;
    }
    .for_textarea{
        margin-left:3%;
        /* width: 300px!important; */
    }
    .second_form_bottom_child {
        width: 100%;
        margin-left: 5px;
    }
    .for_label_bottom{
        /* margin-left:3%; */
        width: 300px;
    }
    .for_textarea_bottom{
        margin-left:3%;
        /* width: 250px!important; */
    }
    .ant-input{
        margin-left:3%;
        /* width: 300px!important; */
    }
}

