#container {
    min-width: 310px;
    max-width: 800px;
    height: 400px;
    margin: 0 auto;
  }
  
  .buttons {
    min-width: 310px;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 0;
  }
  
  .buttons button {
    cursor: pointer;
    border: 1px solid silver;
    border-right-width: 0;
    background-color: #f8f8f8;
    font-size: 1rem;
    padding: 0.5rem;
    transition-duration: 0.3s;
    margin: 0;
  }
  
  .buttons button:first-child {
    border-top-left-radius: 0.3em;
    border-bottom-left-radius: 0.3em;
  }
  
  .buttons button:last-child {
    border-top-right-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    border-right-width: 1px;
  }
  
  .buttons button:hover {
    color: white;
    background-color: rgb(158 159 163);
    outline: none;
  }
  
  .buttons button.active {
    background-color: #0051b4;
    color: white;
  }